import React, { useMemo, useEffect, useState, useRef, useContext } from 'react';
import Header from '../navbar/Header';
import Footer from '../footer/Footer';
import Home from '../../pages/home/Home';
// import FreeLoan from '../../pages/freeloan/FreeLoan';
// import AiExperience from '../../pages/aiexperience/AiExperience';
// import CreditModel from '../../pages/creditmodel/CreditModel';
// import ApplicationProcess from '../../pages/applicationprocess/ApplicationProcess';
// import Promotions from '../../pages/promotions/Promotions';
import AboutUs from '../../pages/aboutus/AboutUs';
import JoinUs from '../../pages/joinus/JoinUs';
// import Faq from '../../pages/faq/Faq';
import Term from '../../pages/term/Term';
import Ordinance from '../../pages/moneyLenderOrdinence/Ordinence';
import Privacy from '../../pages/privacy/Privacy';
import Blogs from '../../pages/blogs/Blogs';
import BlogDetails from '../../pages/blogdetails/BlogDetails';
import { Route, Routes, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import Thanku from '../../pages/landing/thanku/Thanku';
import GKeeper from '../../pages/gKeeper/GKeeper';
import CashLoan from '../../pages/cashloan/CashLoan';
import Offer from '../offer/Offer';
// import SpecialLoan from '../../pages/specialloan/SpecialLoan';
import Deals from '../../pages/deals/Deals';
import DealDetails from '../../pages/dealdetails/DealDetails';
import { LanguageContext } from '../../context/LanguageContext';
import CalculatorPage from '../../pages/calculator/CalculatorPage';
import PreApprovalLoading from 'pages/application/loading';
import ExistingUser from 'pages/application/existinguser';
import EligibilityCheck from 'pages/application/eligibilitycheck';
import EligibilityResult from 'pages/application/result';
import PhoneVericationLayout from 'pages/application/phoneverication';
import ApplicationSignUp from 'pages/application/phoneverication/signup';
import ApplicationOtp from 'pages/application/phoneverication/otp';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function Main() {
  const { pathname, state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const referral = useRef('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isSmall, setIsSmall] = useState(false);
  const [announcement, setAnnouncement] = useState('');
  const [hideAnnoucement, setHideAnnoucement] = useState(false);
  const path = useMemo(() => ['/g-keeper-waiting-list', '/thanku'], []);
  const homePath = useMemo(() => ['/'], []);
  const [match, setMatch] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [offer, setOffer] = useState('');
  const [validDate, setValidDate] = useState(false);
  const { language } = useContext(LanguageContext);
  const lang = language && language === 'zh' ? 'zh-Hant-HK' : 'en';
  let todayDate = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const getAnnoucement = async () => {
      await fetch(`${process.env.REACT_APP_CMS_APP_URL}/api/announcement?locale=${lang}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.data) {
          setOffer(data.data.attributes);
          setValidDate(data.data.attributes.endingDate > todayDate);
        } else {
          setOffer("");
          setValidDate(false);
        }
      }).catch((err) => {
        console.log(err);
      });
    };

    if (pathname === '/') {
      getAnnoucement();
    }
  }, [todayDate, isHome, lang, pathname]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width <= 576) {
      setIsSmall(true);
    }
    if (windowDimensions.width >= 576) {
      setIsSmall(false);
    }
    setAnnouncement(offer.announcementDetail);
  }, [windowDimensions, isHome, offer, language]);

  const annoucementClose = async () => {
    setHideAnnoucement(true);
  }


  useEffect(() => {
    const isMatch = path.includes(pathname);
    isMatch ? setMatch(true) : setMatch(false);
    const referralParams = searchParams.get('r');
    const isHome = homePath.includes(pathname);
    isHome ? setIsHome(true) : setIsHome(false);
    if (pathname === '/' && referralParams && referralParams.length === 5) {
      referral.current = `${pathname}?r=${referralParams}`;
      setMatch(true);
      navigate('/g-keeper-waiting-list');
    }
  }, [pathname, path, searchParams, homePath, navigate]);

  useEffect(() => {
    if (pathname === ('/blog_details') || pathname === ('/exclusive-deals/deal_details')) {
      if (searchParams.get('slug') && searchParams.get('slug').length > 0) {
        const slug = searchParams.get('slug');
        const url = `${pathname}?slug=${slug}`;
        if (!state) {
          navigate(url, { state: { slugRedirect: slug } });
        }
      }
    }
  }, [pathname, searchParams, navigate, state]);

  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: pathname
      }
    });
  }, [pathname]);

  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: pathname
      }
    });
  }, [pathname]);

  return (
    <>
      {
        isHome && offer && validDate && !hideAnnoucement ? <Offer announcement={announcement} isSmall={isSmall} annoucementClose={annoucementClose} /> : null
      }
      {
        match ? null : <Header isSmall={isSmall} validDate={validDate} />
      }
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route  path="/personal_loan/tu_free_loan" element={<FreeLoan />} /> */}
        <Route path="/features/GKeeper" element={<GKeeper />} />
        <Route path="/features/cashloan" element={<CashLoan />} />
        <Route
          path="/features/cashloan/eligibility-check"
          element={<EligibilityCheck />}
        />
        <Route
          path="/features/cashloan/existing-user"
          element={<ExistingUser />}
        />
        <Route
          path="/features/cashloan/pre-approval/loading"
          element={<PreApprovalLoading />}
        />
        <Route
          path="/features/cashloan/sign-up"
          element={<PhoneVericationLayout />}
        >
          <Route index={true} element={<ApplicationSignUp />} />
          <Route
            path="/features/cashloan/sign-up/otp"
            element={<ApplicationOtp />}
          />
        </Route>
        <Route path="/features/cashloan/eligibility-result" element={<EligibilityResult />} />
        {/* <Route path="/features/specialLoan" element={<SpecialLoan />} /> */}
        {/* <Route  path="/personal_loan/ai_experience" element={<AiExperience />} />
          <Route  path="/personal_loan/credit_model" element={<CreditModel />} />
          <Route  path="/personal_loan/application_process" element={<ApplicationProcess />} /> */}
        {/* <Route path="/promotions" element={<Promotions />} /> */}
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog_details" element={<BlogDetails />} />
        <Route path="/calculator" element={<CalculatorPage />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/join_us" element={<JoinUs />} />
        <Route path="/exclusive-deals" element={<Deals />} />
        <Route path="/exclusive-deals/deal_details" element={<DealDetails />} />
        <Route path="/t&c" element={<Term />} />
        <Route path="/money_lender" element={<Ordinance />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route exact path="/thanku" element={<Thanku />} />
      </Routes>
      {
        match ? null : <Footer annoucementActive={announcement && announcement.length > 0}/>
      }
    </>
  )
}

export default Main;
