import React, { useState, useRef, useEffect, useContext } from 'react';
import './header.css';
import logo_horizontal_white from './images/logo_horizontal_white.svg';
import logo_horizontal from './images/logo_horizontal.svg';
import blueburger from './images/blueburger.png'
import whiteburger from './images/whiteburger.png'
import close from './images/close.png';
import apple from './images/apple.svg';
import google_play from './images/google_play.svg';
import line from './images/line.png';
import backup from './images/backup.png';
import backdown from './images/backdown.png'
import lineblue from './images/lineblue.png';
import { Overlay, Popover } from 'react-bootstrap';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../context/LanguageContext';
// import { set } from 'date-fns';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const ReverseThemePages = [
  '/promotions',
  '/blog',
  '/join_us',
  '/faq',
  '/t&c',
  '/money_lender',
  '/privacy',
  '/blog_details',
  '/exclusive-deals',
  '/exclusive-deals/deal_details',
  '/features/cashloan/pre-approval/loading',
  '/features/cashloan/existing-user',
  '/features/cashloan/eligibility-check',
  '/features/cashloan/sign-up',
  '/features/cashloan/sign-up/otp',
];

function Header({ isSmall, expand, validDate }) {
  const [toggled, setToggled] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [show, setShow] = useState(false);
  const [rightMenuShow, setRightMenuShow] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const menuRef = useRef();
  const promotionRef = useRef();
  const navRef = useRef();
  const ref = useRef(null);
  const downloadRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const [isCashLoan, setCashLoan] = useState(false);

  useEffect(() => {
    setCashLoan(pathname.includes('cashloan'));
  }, [pathname]);

  const openAppStore = () => {
    window.open(window.AF_SMART_SCRIPT_RESULT.clickURL, '_blank');
  };

  useEffect(() => {
    setShow(false);
    promotionRef.current?.classList.remove('abc');
    setToggled(false);
    menuRef.current.style.right = "-100%";
    const isWhiteNav = ReverseThemePages.includes(pathname);
    isWhiteNav ? setIsScrolled(true) : setIsScrolled(false);

  }, [pathname]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width >= 1120) {
      setToggled(false);
      //setShow(false);
      menuRef.current.style.display = 'inline-block';
      !toggled || (isScrolled && navRef.current?.classList.add('scrolled'));
      toggled || (!isScrolled && !isCashLoan && downloadRef.current?.classList.remove('download-toggled'));
    }
    if (windowDimensions.width <= 1122 && !toggled) {
      menuRef.current.style.right = '-100%';
      !toggled && setShow(false);
      !toggled && setRightMenuShow(false);
    }

  }, [windowDimensions, isScrolled, toggled, show, rightMenuShow, isCashLoan]);


  window.onscroll = () => {
    const isWhiteNav = ReverseThemePages.includes(pathname);
    if (isWhiteNav) {
      setIsScrolled(true)
    } else
      setIsScrolled(window.scrollY <= 50 ? false : true);
    return () => (window.onscroll = null);
  };

  const menuHandle = (e) => {
    setToggled(!toggled);
    menuRef.current.style.right = `${!toggled ? '0' : "-100%"}`;
    !toggled || (isScrolled && navRef.current?.classList.add('scrolled'));
    toggled || (!isScrolled && navRef.current?.classList.remove('scrolled'));
    !toggled || (isScrolled && downloadRef.current?.classList.add('download-toggled'));
    toggled || (!isScrolled && downloadRef.current?.classList.remove('download-toggled'));
    isCashLoan && downloadRef.current?.classList.add('download-toggled');
    handleLeave(e);
  }

  const ChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    changeLanguage(lang);
    setShow(false);
    setRightMenuShow(false);
    promotionRef.current?.classList.remove('abc');
    setToggled(false);
    menuRef.current.style.right = "-100%";
  }
  const handleEnter = (event) => {
    setShow(true);
  };

  const handleLeave = (event) => {
    setShow(false);
  }

  useEffect(() => {
    if(windowDimensions.width <= 1122 ) {
      show && !rightMenuShow ? promotionRef.current?.classList.add('xyz') : promotionRef.current?.classList.remove('xyz');
      rightMenuShow && show ? promotionRef.current?.classList.add('abc') : promotionRef.current?.classList.remove('abc');
      if(!show) {
        setRightMenuShow(false);
      }
    }
  },[show, rightMenuShow, windowDimensions.width])

  const handleClick = (e) => {
    setShow(prevState => !prevState);
  }
  
  const getLogoImage = () => {
    if (isCashLoan) {
      return logo_horizontal; 
    } else {
      return isScrolled || toggled ? logo_horizontal : logo_horizontal_white;
    }
  }

  const getTextClass = () => {
    if (isCashLoan) {
      return "dropdown-scroll personal-loan";
    } else {
      return isScrolled ? "dropdown-scroll personal-loan" : "personal-loan";
    }
  }

  const getNavTextClass = () => {
    if (isCashLoan) {
      return "dropdown-scroll nav-listItem dropdown-list";
    } else {
      return isScrolled ? " dropdown-scroll nav-listItem dropdown-list" : "nav-listItem";
    }
  }

  const getLine = () => {
    if (isCashLoan) return lineblue;
    return isScrolled || toggled ? lineblue : line;
  }

  return (
    <Container fluid>
      <Row>
        <nav ref={navRef} className={isScrolled ? "navBar scrolled" : toggled ? "gray navBar" : "navBar"}>
          <Col lg={2} md={2} className='d-flex justify-content-center'>
            <Link className={pathname.includes("deals") ? "nolink" : "logoLink"} to={pathname.includes("deals") ? `${pathname}?${searchParams}` : "/"}>
              <img className="logo-img" src={getLogoImage()} alt="Grantit" />
            </Link>
          </Col>
          <Col lg={6} md={6} sm={6} xs={1}>
            <ul ref={menuRef} className="nav-list">
              {!pathname.includes('deal') && (
                <>
                  <li ref={promotionRef} className="nav-listItem"
                    onMouseEnter={(e) => windowDimensions.width > 580 ? handleEnter(e) : null}
                    onMouseLeave={(e) => windowDimensions.width > 580 ? handleLeave(e) : null}
                    onClick={handleClick} 
                  >
                    <div className="personalLoan" ref={ref} >
                      <p className={getTextClass()} >{t('header.personalLoan')}</p>
                      <img src={show ? backup : backdown} className="category" alt="" />
                    </div>
                    <Overlay
                      show={show}
                      target={promotionRef}
                      placement="bottom"
                      container={promotionRef}
                      containerPadding={15}
                    >
                      <Popover id="popover-contained" >
                        <Popover.Body>
                          <ul className="dropdown nav-lang" >
                            <li style={{ 'marginTop': '8px' }} className="dropdown-list"><Link className={windowDimensions.width <= 1122 && "set-font"}  to="/features/cashloan">{t('header.personalLoanTu')}</Link></li>
                            <li style={{ 'marginTop': '8px' }} className="dropdown-list"><Link className={windowDimensions.width <= 1122 && "set-font"}  to="/features/GKeeper">{t('header.smartFinancialManagement')}</Link></li>
                          </ul>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </li>
                  {/* <li className={getNavTextClass()}><Link to="promotions">{t('header.Promotions')}</Link></li> */}
                  <li className={getNavTextClass()}><a href='https://blogs.grantit.io'>{t('header.blog')}</a></li>
                  <li className={getNavTextClass()}><Link to="calculator">{t('header.goalsCalculator')}</Link></li>
                  <li className={getNavTextClass()}><Link to="about_us">{t('header.AboutUs')}</Link></li>
                </>
              )}
              <li className="i18lang">
                <div className="language">
                  <div className="localization">
                    <p onClick={() => ChangeLanguage('zh')} className="localization">中</p>
                    <img src={lineblue} className="lang-line" alt="" />
                    <p onClick={() => ChangeLanguage('en')} className="localization">EN</p>
                  </div>
                </div>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={4} xs={9} sm={4}>
            <ul className="nav-lang">
              <Row className="download-row">
                <Col lg={8} sm={6} className='d-flex justify-content-end'>
                {!pathname.includes('deal') && (<li >
                    {/* <div className="download"> */}
                    <div className={`download-btn ${language === 'zh' ? 'download-btn-cn': ''}`} onClick={openAppStore}>
                      {t('header.DownloadNow')}
                      <div className="download-icons">
                        <img src={apple} alt="" onClick={openAppStore}/>
                        <img src={google_play} alt="" onClick={openAppStore} />
                      </div>
                    </div>
                  </li>
                )}
                </Col>
                <Col lg={4} sm={6}>
                  {!pathname.includes("deal_details") &&
                    <li >
                      <div className="language">
                        <div className="localization">
                          <p onClick={() => ChangeLanguage('zh')} className={isCashLoan ? "change-langdown change-lang" : isScrolled ? "change-langdown change-lang" : "change-lang"}>中</p>
                          <img src={getLine()} className="lang-line" alt="" />
                          <p onClick={() => ChangeLanguage('en')} className={isCashLoan ? "change-langdown change-lang" : isScrolled ? "change-langdown change-lang" : "change-lang"}>EN</p>
                        </div>
                      </div>
                    </li>
                  }
                </Col>
              </Row>
            </ul>
            <Row>
            <div className="download-row-small justify-content-end">
              {!pathname.includes('deal') ? 
              (<>
                <div className="download-small ">
                  <div
                    ref={downloadRef}
                    className={isScrolled || toggled || isCashLoan ? "download-toggled text-center" : "download-smBtn text-center"}
                    onClick={openAppStore}
                  >
                    {t('header.Download')}
                  </div>
                </div>
                <div className="burger-menu">
                  <img onClick={menuHandle} className="burger" src={toggled ? close : (isCashLoan && !toggled) ? blueburger : !toggled ? !isScrolled ? whiteburger : blueburger : close} alt="" />
                </div>
              </>
              ) : (
                <>
                  {!pathname.includes("deal_details") &&
                    <Col md={12} className="dealMobileLang">
                      <div className="language">
                        <div className="localization">
                          <p onClick={() => ChangeLanguage('zh')} className={"change-lang"}>中</p>
                          <img src={getLine()} className="lang-line" alt="" />
                          <p onClick={() => ChangeLanguage('en')} className={"change-lang"}>EN</p>
                        </div>
                      </div>
                    </Col>
                  }
                </>
              )
            }
            </div>
              {/* <Col xs={4} sm={4}>
              </Col> */}
            </Row>
          </Col>
        </nav>

      </Row>
    </Container>
  )
}

export default Header
