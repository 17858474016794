import React, { useContext, useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import gKeeperBanner from './images/gkeeperBanner.svg';
import gKeeperBannerCn from './images/gKeeperBannerCn.svg';
import gKeeperBannerMobile from './images/gkeeperBannerMobile.png';
import gKeeperBannerMobileCn from './images/gKeeperBannerMobileCn.png';
import keeperStep1 from './images/keeperStep1.png';
import keeperStep1Cn from './images/keeperStep1Cn.png';
import keeperStep2 from './images/keeperStep2.png';
import keeperStep2Cn from './images/keeperStep2Cn.png';
import keeperStep3 from './images/keeperStep3.png';
import keeperStep3Cn from './images/keeperStep3Cn.png';
import keeperStep4 from './images/keeperStep4.png';
import step1 from './images/step1.png';
import step2 from './images/step2.png';
import step3 from './images/step3.png';
import arrow from './images/arrow.svg';
import rightArrow from './images/rightArrow.svg';
import gkeeperTable from './images/gkeeperTable.svg';
import gkeeperTableCn from './images/gkeeperTableCn.svg';
import Howwork from '../../components/howworks/Howwork';
import GetStarted from '../../components/getstarted/GetStarted';
import { LanguageContext } from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import './gkeeper.css';
import ReactMarkdown from 'react-markdown';
import appleImg from '../home/images/appleImg.svg';
import googleImg from '../home/images/googleImg.svg';
import Faq from '../../components/gkeeperfaq/Faq';
import SEO from '../../SEO';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function GKeeper() {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    // const [smallScreen, setSmallScreen] = useState(false);

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    // useEffect(() => {
    //     if (windowDimensions.width <= 576) {
    //       setSmallScreen(true);
    //     }
    //     if (windowDimensions.width >= 576) {
    //       setSmallScreen(false)
    //     }
    //   }, [windowDimensions]);

    const handleRedirect = (link) => {
        window.open(window.AF_SMART_SCRIPT_RESULT.clickURL, '_blank');
    };

    const bannerBkg = () => {
        if (window.innerWidth < 992) {
            return language === 'zh' ? gKeeperBannerMobileCn : gKeeperBannerMobile;
        } else {
            return language === 'zh' ? gKeeperBannerCn : gKeeperBanner;
        }
      };

    return (
            <div>
                <SEO title={t('gkeeper.pageTitle')} description={t('gkeeper.metaDescription')} />
                <Container fluid className="gkeeper p-0 overflow-hidden" data-aos="slide-down" data-aos-duration="700"
                style={{ backgroundImage: `url(${bannerBkg()})`}}>
                <Row className="justify-content-center h-100">
                        <Col sm={12} lg={6} xl={5} md={12} xs={12} className="px-4 my-auto">
                            <p className="gkeeperHeading">{t('gkeeper.bannerHeading')}</p>
                            <p className="gkeeperDesc"><ReactMarkdown children={t('gkeeper.bannerSubheading')} /></p>
                            <p className="gkeeperDetail"><ReactMarkdown children={t('gkeeper.bannerDetail')} /></p>
                            <div className="storeLinks">
                                <img src={appleImg} className="appStore" alt="appStore" onClick={() => handleRedirect('apple')}/>                
                                <img src={googleImg} className="googlePlay" alt="playStore" onClick={() => handleRedirect('google')} />
                            </div>
                        </Col>
                        <Col sm={12} lg={5} md={12} xs={12} className="d-flex justify-content-center">
                            {/* <img src={language === 'zh' ? gkeeperPhoneCn : gkeeperPhone} alt="phone" className="gkeeperPhone" /> */}
                        </Col>
                    </Row>
                </Container>
            <Container className="keeperTable p-0 overflow-hidden">
                <p className="keeperTableHead">{t('gkeeper.tableHeading')}</p>
                <Row className="justify-content-center px-2">
                    <Col lg={11} md={12}>
                        <img src={language === 'zh' ? gkeeperTableCn : gkeeperTable} alt="gkeeperTable" 
                        className={language === 'zh' ? "gkeeperTableCn" : "gkeeperTable"} />
                    </Col>
                    <Col lg={11} md={12}>
                        <p className="tableDisclaimer">{t('gkeeper.tableDisclaimer')}</p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-0 overflow-hidden kepperContent">
                <div className="HeadingAreaLArge">
                    <p className="keeper1Heading px-2">{t('gkeeper.section1Heading')}</p>
                    <p className="keeper1Desc px-2">{t('gkeeper.section1Desc')}</p>
                </div>
                <Row>
                    <Col lg={6} sm={12} xs={12} className="keeperLeft mt-5">
                        <Row className="justify-content-end">
                            <Col lg={8} md={12} sm={12}>
                                <div className="px-2">
                                    <p className="keeperDetailHead">{t('gkeeper.section1P1Heading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section1P1Desc')}</p>
                                </div>
                                <div className="keeperDetails px-2">
                                    <p className="keeperDetailHead">{t('gkeeper.section1P2Heading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section1P2Desc')}</p>
                                </div>
                                <div className="keeperDetails px-2">
                                    <p className="keeperDetailHead">{t('gkeeper.section1P4Heading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section1P4Desc')}</p>
                                </div>
                                <div className="keeperDetails d-flex keeperDetailLink px-2">
                                    <div className="my-auto">
                                        <p className="keeperLink my-auto" onClick={() => handleRedirect("general")}>{t('gkeeper.section1Link')}</p>
                                    </div>
                                    <img src={rightArrow} className="rightArrow" alt="icon" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5} md={12} sm={12} xs={12} className="d-flex justify-content-end keeeprRight">
                        <img src={language === 'zh' ? keeperStep1Cn : keeperStep1} alt="keeperStep1" className="keeperStep1" />
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-0 overflow-hidden kepperContent">
                <div className="HeadingAreaLArge">
                    <p className="keeper1Heading px-2">{t('gkeeper.section2Heading')}</p>
                    <p className="keeper1Desc px-2">{t('gkeeper.section2Desc')}</p>
                </div>
                <Row>
                    <Col lg={6} md={12} xs={12} className="d-flex justify-content-end keeeprRight2">
                        <img src={language === 'zh' ? keeperStep2Cn : keeperStep2} alt="keeperStep1" className="keeperStep1"
                         style={{ marginTop: '20px' }}/>
                    </Col>
                    <Col lg={6} md={12} xs={12} >
                        <Row className="justify-content-start">
                            <Col md={8} sm={12}>
                                <div className="keeperDetails px-2">
                                    <p className="keeperDetailHead">{t('gkeeper.section2P1Heading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section2P1Desc')}</p>
                                </div>
                                <div className="keeperDetails px-2">
                                    <p className="keeperDetailHead">{t('gkeeper.section2P2Heading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section2P2Desc')}</p>
                                </div>
                                <div className="keeperDetails d-flex keeperDetailLink px-2">
                                    <div className="my-auto">
                                        <p className="keeperLink my-auto" onClick={() => handleRedirect("general")}>{t('gkeeper.section2Link')}</p>
                                    </div>
                                    <img src={rightArrow} className="rightArrow" alt="icon" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-0 overflow-hidden kepperContent">
                <div className="HeadingAreaLArge px-2">
                    <p className="keeper1Heading">{t('gkeeper.section3Heading')}</p>
                    <p className="keeper1Desc">{t('gkeeper.section3Desc')}</p>
                </div>
                <Row>
                    <Col lg={6} md={12} xs={12} className="keeperLeft mt-5">
                        <Row className="justify-content-end">
                            <Col lg={8} md={12} sm={12}>
                                <div className="keeperDetails px-2">
                                    <p className="keeperDetailHead">{t('gkeeper.section3P1Heading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section3P1Desc')}</p>
                                </div>
                                <div className="keeperDetails px-2">
                                    <p className="keeperDetailHead">{t('gkeeper.section3P2Heading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section3P2Desc')}</p>
                                </div>
                                <div className="keeperDetails d-flex keeperDetailLink px-2">
                                    <div className="my-auto">
                                        <p className="keeperLink my-auto" onClick={() => handleRedirect("general")}>{t('gkeeper.section3Link')}</p>
                                    </div>
                                    <img src={rightArrow} className="rightArrow" alt="icon" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5} md={12} xs={12} className="d-flex keeperRight">
                        <img src={language === 'zh' ? keeperStep3Cn : keeperStep3} alt="keeperStep3" className="keeperStep3"/>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-0 overflow-hidden kepperContent">
                <div className="HeadingAreaLArge px-2">
                    <p className="keeper1Heading">{t('gkeeper.section4Heading')}</p>
                    <p className="keeper1Desc">{t('gkeeper.section4Desc')}</p>
                </div>
                <Row>
                    <Col xl={5} lg={5} md={12} sm={12} xs={12} className={`d-flex ${windowDimensions.width > 992 ? 'justify-content-end' : 'justify-content-center'}`}>
                        <img src={keeperStep4} alt="keeperStep1" className="keeperStep4" />
                    </Col>
                    <Col sm={1} />
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                        <Row className="justify-content-start">
                            <Col xl={8} lg={12} md={12} sm={12}>
                                <div className="keeperDetails px-2">
                                    <p className="keeperAes">{t('gkeeper.section4AESHeading')}</p>
                                    <p className="keeperDetailDesc">{t('gkeeper.section4AESDesc')}</p>
                                </div>
                                <div className="keeperDetails px-2">
                                    <p className="keeperNoHead">{t('gkeeper.section4P1Heading')}</p>
                                    <p className="keeperNoDesc">{t('gkeeper.section4P1Desc')}</p>
                                </div>
                                <div className="keeperDetails px-2">
                                    <p className="keeperNoHead">{t('gkeeper.section4P2Heading')}</p>
                                    <p className="keeperNoDesc">{t('gkeeper.section4P2Desc')}</p>
                                </div>
                                <div className="keeperDetails d-flex keeperDetailLink px-2">
                                    <div className="my-auto">
                                        <p className="keeperLink my-auto" onClick={() => handleRedirect("general")}>{t('gkeeper.section4Link')}</p>
                                    </div>
                                    <img src={rightArrow} className="rightArrow" alt="icon" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="p-0 overflow-hidden keeperSteps">
                <h1 className="appHeading stepsDetail">{t('gkeeper.applicationHeading')}</h1>
                <Row className="justify-content-center">
                    <div style={{ 'maxWidth': '740px' }}>
                        <Row className="justify-content-lg-center">
                            <Col className="d-flex justify-content-center step1Position" sm={12} md={12} lg={3} xs={12}>
                                <Howwork img={step1} alt="step1" title={t('home.works.step1')} desc={t('gkeeper.applicationStep1')} />
                            </Col>
                            <Col className='my-auto d-lg-block d-none ' lg={1} md={0} sm={0} xs={0}><img src={arrow} alt="" /></Col>
                            <Col className="d-flex justify-content-center step1Position" sm={12} md={12} lg={3} xs={12}>
                                <Howwork img={step2} alt="step2" title={t('home.works.step2')} desc={t('gkeeper.applicationStep2')} />
                            </Col>
                            <Col className='my-auto d-lg-block d-none' lg={1} md={0} sm={0} xs={0}><img src={arrow} alt="" /></Col>
                            <Col className="d-flex justify-content-center step1Position" sm={12} md={12} lg={3} xs={12} >
                                <Howwork img={step3} alt="step3" title={t('home.works.step3')} desc={t('gkeeper.applicationDone')} />
                            </Col>
                        </Row>
                    </div>
                </Row>
            </Container>
            <div className="spacingFaq" />
            <Faq />
            <div className="mt-5">
                <GetStarted  desc={t('signUp.signDescGKeeper')} isGKeeper={true} />
            </div>
        </div>
    )
}

export default GKeeper