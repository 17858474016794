import React, { useEffect, useState } from 'react'
import './footer.css';
import {Container, Row, Col} from 'react-bootstrap';
import logo_horizontal_white from './../navbar/images/logo_horizontal_white.svg';
import facebook from './images/facebook.svg';
import youtube from './images/youtube.svg';
import instagram from './images/instagram.svg';
import linkedin from './images/linkedin.svg';
import line from './images/line.svg';
import lineMobile from './images/lineMobile.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import appleImg from '../../pages/home/images/appleImg.svg';
import googleImg from '../../pages/home/images/googleImg.svg';
import ReactMarkdown from 'react-markdown';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function Footer({ annoucementActive }) {
    const { t } = useTranslation();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const redirectLink = (link) => {
        if(link === 'fb') window.open('https://www.facebook.com/grantithk', '_blank');
        if(link === 'youtube') window.open('https://www.youtube.com/channel/UCzZBFqFJt62RrZScTWQ0BTg', '_blank');
        if(link === 'insta') window.open('https://www.instagram.com/grantit.io', '_blank');
        if(link === 'linkedin') window.open('https://www.linkedin.com/company/grantit-limited/?viewAsMember=true', '_blank');
        if (['google', 'apple'].includes(link)) window.open(window.AF_SMART_SCRIPT_RESULT.clickURL);
    }
    return (
        <div className="footer" >
            <Container fluid>
                <Row>
                    <Col lg={4} className="footerleft">
                        <Row className="justify-content-center">
                            <Col lg={10}>
                        <img className="footer-logo" src={logo_horizontal_white} alt="GRANTIT"/>
                        <div className="address">
                            <p><ReactMarkdown children={t('footer.address')} /></p>
                            <p>{t('footer.helplineText')}: <span className="no-break">(852) 2577 7117</span></p>
                        </div>
                        </Col>
                        </Row>
                    </Col>
                    <Col lg={8} className="footer-right">  
                    <Row>
                        <Col sm={12}>
                        <div className="social-links px-3">
                            <ul className="linkList">
                                <li><div onClick={() => redirectLink('fb')} className="link"><img className="social-icon" src={facebook} alt=""/></div></li>
                                <li><div onClick={() => redirectLink('youtube')} className="link"><img className="social-icon" src={youtube} alt=""/></div></li>
                                <li><div onClick={() => redirectLink('insta')} className="link"><img className="social-icon" src={instagram} alt=""/></div></li>
                                <li><div onClick={() => redirectLink('linkedin')} className="link"><img className="social-icon" src={linkedin} alt=""/></div></li>
                            </ul>     
                        </div>
                        </Col>
                        <Col sm={12}>
                            <div className="appStoreLinks">
                                <img src={appleImg} className="appStore" alt="" onClick={() => redirectLink('apple')}/>
                                <img src={googleImg} className="googlePlay" alt="" onClick={() => redirectLink('google')}/>
                            </div>
                        </Col>
                        <Col sm={12}>
                        <div className="footer-links">
                        <ul className="linkList">
                                <li className="footer-list"><Link to="join_us" className="link footerlinks">{t('footer.joinUs')}</Link></li>
                                {/* <li className="footer-list"><Link to="faq" className="link footerlinks">{t('footer.Faq')}</Link></li> */}
                                <li className="footer-list"><Link to="t&c" className="link footerlinks">{t('footer.term')}</Link></li>
                                <li className="footer-list"><Link to="money_lender" className="link footerlinks">{t('footer.Lender')}</Link></li>
                                <li className="footer-list"><Link to="privacy" className="link footerlinks">{t('footer.privacy')}</Link></li>
                            </ul>
                        </div>
                        </Col>
                    </Row>
                    </Col>
                    <Col lg={12} className="horizontalline">
                        <img className="footerline" src={windowDimensions.width > 992 ? line : lineMobile} alt="line" />
                    </Col>
                    <Col lg={12}>
                        <h3 className="warning">{t('footer.warning')}</h3>
                    </Col>
                    <Col lg={12}>
                        { windowDimensions.width > 992 ? 
                            <p className={`license ${annoucementActive ? 'announcementStyle' : ''}`}>{t('footer.companyName')} &nbsp;&nbsp;|&nbsp;&nbsp; {t('footer.moneyLender')} 1580/2023</p>
                            :
                            <div className={`license ${annoucementActive ? 'announcementStyle' : ''}`}>
                                <p>{t('footer.companyName')} <br />{t('footer.moneyLender')} 1580/2023</p>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer
